import './App.css';
import './accordion-item/AcItem';
import AcItem from './accordion-item/AcItem';
import Title from './title/Title';

import { useState } from 'react';

function App() {

  const today = Date.now();

  const [menu, setMenu] = useState([
    {"name": "Pamonha", "icon": "fa-solid fa-utensils", "price": 10},
    {"name": "Curau", "icon": "fa-solid fa-mug-hot", "price": 7},
    {"name": "Café", "icon": "fa-solid fa-mug-hot", "price": 2},
    {"name": "Pipoca", "icon": "fa-solid fa-bowl-rice", "price": 3},
    {"name": "Galinhada", "icon": "fa-solid fa-drumstick-bite", "price": 5},
    {"name": "Churrasquinho", "icon": "fa-solid fa-cow", "price": 10},
    {"name": "Canjica", "icon": "fa-solid fa-mug-hot", "price": 5},
    {"name": "Pastel", "icon": "fa-solid fa-table-cells", "price": 6},
    {"name": "Bolo no pote", "icon": "fa-solid fa-jar", "price": 5},
    {"name": "Caldo", "icon": "fa-solid fa-bowl-food", "price": 6},
    {"name": "Refri", "icon": "fa-solid fa-glass-water", "price": 2},
    {"name": "Água mineral", "icon": "fa-solid fa-bottle-water", "price": 3},
    {"name": "Maçã do amor", "icon": "fa-solid fa-apple-whole", "price": 8},
    {"name": "Uva do amor", "icon": "fa-solid fa-cubes-stacked", "price": 8},
    {"name": "Uva com chocolate", "icon": "fa-solid fa-cubes-stacked", "price": 10},
    {"name": "Morango com chocolate", "icon": "fa-solid fa-leaf", "price": 15},
    {"name": "Batata Chips", "icon": "fa-solid fa-cookie-bite", "price": 10},
    {"name": "Cachoro-quente", "icon": "fa-solid fa-hotdog", "price": 5},
    {"name": "Fatia de bolo", "icon": "fa-solid fa-cheese", "price": 5},
    {"name": "Mini-pizza", "icon": "fa-solid fa-pizza-slice", "price": 5},
    {"name": "Pula-pula", "icon": "fa-solid fa-face-laugh", "price": 10},
  ]);
  
  const [qtts, setQtts] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]);
  const [subtotal, setSubtotal] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]);
  const [receipt, setReceipt] = useState(false);

  function handleToggleReceipt(){
    setReceipt(!receipt);
  }

  function handleMinusClick(i){
    if(qtts[i] > 0){
      let tmp1 = qtts.slice();
      tmp1[i] -= 1;
      let tmp2 = menu.map((t, key) => t.price * tmp1[key]);
      setQtts(tmp1);
      setSubtotal(tmp2);
    } 
  }

  function handlePlusClick(i){
    let tmp1 = qtts.slice();
    tmp1[i] += 1;
    let tmp2 = menu.map((t, key) => t.price * tmp1[key]);
    setQtts(tmp1);
    setSubtotal(tmp2);
  }
  const receiptEmpty = (<div class="row"><div class="col">Nenhum item selecionado.</div><hr /></div>)

  const receiptItems = menu
  .map((produto, index) => {
    const quantidade = qtts[index];
    return {
      nome: produto.name,
      qtd: quantidade,
      subtotal: produto.price * quantidade
    };
  })
  .filter(produto => produto.qtd > 0).map((e, key)=> (<div className="nbox"><div className="row">
    <div className="col-1">
    <p>{key + 1}</p>
    </div>
    <div className="col-9 text-start">
    <p>{e.nome} x {e.qtd}</p>
    </div>
    <div className="col-2 text-end">
    <p className="float-end">{e.subtotal.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}
    </p>
    </div>
</div><hr className="hrnp" /></div>));
  const total = subtotal.reduce((sum, num) => sum + num);

  const title = <Title />;
  const items = menu.map((item, index) =>
    <AcItem key={index} itemKey={index} itemIcon={item.icon} itemName={item.name} itemPrice={item.price} itemQty={qtts} itemTotal={subtotal} minusClick={() => handleMinusClick(index)} plusClick={() => handlePlusClick(index)} />
  );
  const receiptBody = (<div className="card" >
    <div className="card-body mx-4">
        <div className="container">
        <p className="my-5 mx-5" style={{fontSize:'30px'}}>Obrigado por usar o Rapidex</p>
        <div className="row">
            <ul className="list-unstyled">
            <li className="text-muted mt-1"><span className="text-black">Orçamento</span> #12345</li>
            <li className="text-black mt-1">{new Intl.DateTimeFormat('pt-br', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today)}</li>
            </ul>
            <hr className="hrnp" />
            {total > 0 ? receiptItems : receiptEmpty }
        </div>
        <div className="nbox">
        <div className="row text-black">
            <div className="col-xl-12 mt-2 mb-0">
            <p className="float-end fw-bold">Total: {total.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}
            </p>
            </div>
            <hr style={{border:'2px solid black'}} />
        </div>
        </div>
        <div className="text-center" style={{marginTop:'90px'}}>
            <p>Facilite o troco. </p>
            <button type="button" className="btn form-control btn-outline-primary" onClick={handleToggleReceipt}>Fechar</button>
        </div>
        </div>
    </div>
    </div>);

  return (
    <div className="App">
      {!receipt ? (      <div className="mx-auto">
        {title}
        <div className="accordion mt-2" id="accordionExample">
          {items}
        </div>
        <div className="card mt-2">
        <div className="card-header">
            <div className="row">
            <div className="col text-start"><h1 className="text-center"><strong>{total.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}</strong></h1></div>
            <div className="col text-end"><h1><button type="button" className="btn btn-outline-dark" onClick={handleToggleReceipt} disabled={total === 0}><i className="fa-solid fa-receipt"></i></button></h1></div>
            </div>
        </div>
      </div>
      </div>) : (receiptBody)}
    </div>
  );
}

export default App;
