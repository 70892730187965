import './AcItem.css';

function AcItem({itemKey, itemName, itemIcon, itemQty, itemTotal, minusClick, plusClick}) {
  return (
    <div className="accordion-item">
    <h2 className="accordion-header">
        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse' + itemKey} aria-expanded="true" aria-controls={'#collapse' + itemKey}>
            <i className={itemIcon}></i>&nbsp;{itemName}&nbsp;<span className="badge text-bg-dark">{itemTotal[itemKey].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
        </button>
    </h2>
    <div id={'#collapse' + itemKey} className="accordion-collapse" data-bs-parent="#accordionExample">
        <div className="accordion-body">
            <div className="input-group input-group-lg mb-3">
                <button className="btn btn-outline-danger" type="button" id="button-addon1" onClick={minusClick}>-</button>
                <input type="text" style={{textAlign:'center'}} className="form-control" placeholder="" aria-label="" aria-describedby="button-addon1" value={itemQty[itemKey]} readOnly />
                <button className="btn btn-outline-success" type="button" id="button-addon2" onClick={plusClick}>+</button>
            </div>
        </div>
    </div>
    </div>
  );
}

export default AcItem;
