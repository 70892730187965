import './Title.css';

function Title() {
  return (
    <div className="Title card mt-2">
        <div className="card-header">
            <h1 className="text-center"><strong>Rapidex</strong></h1>
            <h3>Arraiá do CEMI Gama 2024</h3>
        </div>
    </div>
  );
}

export default Title;
